import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Img from '../components/Img'
import { Layout } from '../components/layout'

const availableLanguages = ['sv', 'en', 'de']
const MuseumPage = (props: any) => {
  const location = props.location.search.match(/lang=(\w\w)/i)
  const queryLang = location && location[1]

  const navigatorLang =
    typeof window !== 'undefined' &&
    window.navigator.languages.filter(l => availableLanguages.indexOf(l) !== -1)[0]

  console.log(queryLang, props, navigatorLang)
  const lang = queryLang || navigatorLang || 'sv'

  const info = props.data.text.edges.find(e =>
    e.node.fileAbsolutePath.match(new RegExp(`${lang}.md$`)),
  ).node

  let googleTranslateDisclaimer
  if (lang === 'de') {
    googleTranslateDisclaimer = (
      <>
        <p className="center small">This text was translated using Google Translate</p>
        <p className="center">
          Click <Link to="?lang=en">here</Link> for the original
        </p>
      </>
    )
  }
  return (
    <Layout {...props}>
      <div className="museum">
        <section>
          <Img sizes={props.data.image.sizes} />
        </section>

        {googleTranslateDisclaimer}

        <section className="text" dangerouslySetInnerHTML={{ __html: info.html }} />
      </div>
    </Layout>
  )
}

export default MuseumPage

export const MuseumQuery = graphql`
  query MuseumQuery {
    image: imageSharp(fluid: { originalName: { regex: "/suf/" } }) {
      sizes(maxWidth: 2560, quality: 90) {
        ...GatsbyImageSharpSizes_withWebp
      }
    }
    text: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/museum.*.md" } }) {
      edges {
        node {
          html
          fileAbsolutePath
        }
      }
    }
  }
`
